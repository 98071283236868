@import (reference) '~/stylesheets/core.less';

@keyframes slideUp {
  0% {
    margin-bottom: -1000px;
  }
  100% {
    margin-bottom: 0;
  }
}

@modal-top-margin: 40px;

.marketplace__modal-root-content {
  position: absolute;
  top: @modal-top-margin;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none;
  background: @color-white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.5) 0 5px 15px;
  max-height: 90vh;

  @media (max-width: @screen-xs-max) {
    top: unset;
    bottom: 0;
    width: 100%;
    height: fit-content;
    max-height: 100vh;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: @spacing-16;
  }

  &.modal-small {
    @media (max-width: @screen-xs-max) {
      top: 64% !important;
    }
  }

  &.mobile-half-sheet {
    .mobile({
      max-height: none;
      top: auto;
      animation-name: slideUp;
      animation-duration: 0.25s;
    });
  }

  &.close-button-tooltip {
    // Needs visible overflow to allow ReactToolTip to appear on close button hover
    overflow: visible;
    .mobile({
      overflow-y: scroll;
    });
  }

  &.mobile-top-border {
    .mobile({
      top: 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    });
  }
}

.modal-button-tooltip {
  width: 100%;
  max-width: 230px;
  // cant override these with props. data-background-color data-text-color only accept
  // generic values and not rgb or hex values
  background-color: @color-white !important;
  color: @color-black !important;
  margin-left: @spacing-md !important;

  &::before,
  &::after {
    border-right-color: @color-white !important;
  }
}
