@import (reference) '~/stylesheets/core.less';

.comingSoonStorefront {
  .content {
    display: flex;
    flex-direction: column;
    gap: @spacing-16;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 192px;
    padding-bottom: 192px + @spacing-32;

    .tablet({
      padding-left: @spacing-24;
      padding-right: @spacing-24;
    });

    .mobile({
      padding-top: 96px;
      padding-left: @spacing-24;
      padding-right: @spacing-24;
      padding-bottom: 96px + @spacing-32;
    });

    .heading {
      font-family: @font-family-new-spirit;
    }

    .subheading {
      display: flex;
      gap: @spacing-8;

      .icon {
        position: relative;
        top: 1px;
      }
    }
  }
}
