@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.seoLinks {
  max-width: 1920px;
  margin-top: @spacing-32;
  margin-left: auto;
  margin-right: auto;
  padding-left: @spacing-lg;
  padding-right: @spacing-lg;
  padding-bottom: @spacing-32;
  border-top: 1px solid @color-black-030;

  @media (min-width: 1920px) {
    border-left: 1px solid @color-black-030;
    border-right: 1px solid @color-black-030;
  }

  .mobile({
    padding-left: @spacing-md;
    padding-right: @spacing-md;
  });

  h2 {
    margin-top: @spacing-lg;
    margin-bottom: @spacing-md;
  }

  h3 {
    margin-top: @spacing-md;
    margin-bottom: @spacing-md;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: @spacing-md;
    list-style: none;
    padding: 0;
  }

  li {
    flex: none;
    width: 25%;
    margin-bottom: @spacing-sm;
    padding-right: @spacing-sm;
    vertical-align: top;
    font-size: @font-size-p;

    .desktop-sm({
      width: 50%;
    });

    .tablet({
      width: 50%;
    });

    .mobile({
      width: 100%;
    });
  }
}
